.welcometo {
  position: relative;
  height: 90vh;
  clip-path: circle(184vh at 75vh -95vh);
  box-shadow: inset 20vh 3vh 50vh rgba($color: #000000, $alpha: 0.16);
  background-image: url("../../assets/images/frontimg.jpg");
  background-size: cover;
  background-position: center;
  margin-bottom: 15vh;
  @media (max-width: 992px) {
    clip-path: circle(110% at 40% 7%);
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Regular" !important;
    color: $dark !important;
    font-size: 16px !important;
    .welcome {
      color: $primary;
      // margin-bottom: -10px;
    }
    .izone {
      font-family: "Bold" ;
      font-size: 70px;
      position: relative;
      @media (max-width: 992px) {
        font-size: 35px;
      }
      &::after {
        content: "IZONE";
        position: absolute;
        color: transparent;
        background-image: repeating-linear-gradient(
          transparent 0px,
          transparent 2px,
          white 5px,
          white 0px
        );
        @media (max-width: 992px) {
          background-image: repeating-linear-gradient(
          transparent 0px,
          transparent 1px,
          white 2.5px,
          white 0px
        );
        }
        -webkit-background-clip: text;
        top: 3px;
        left: 0px;
      }
      span:last-child {
        font-family: "Roman";
      }
    }
    .text {
      padding-bottom: 20px;
      .blue {
        color: $primary;
      }
    }
    .check {
      font-family: "SemiBold";
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      width: fit-content;
      margin-bottom: 20px;
      .blue {
        .anticon {
          transition-duration: 0.5s;
        }
        height: 1rem;
        margin-left: 10px;
        color: $primary;
      }
      a {
        color: $dark;
      }
      &:hover {
        .blue {
          .anticon {
            transform: translateX(15px);
          }
        }
      }
    }
    .download {
      font-family: "SemiBold";
      background-color: $primary;
      color: $white;
      border: none;
      width: fit-content;
      border-radius: 59px;
      padding: 13px;
      margin-left: 6px;
      margin-bottom: 6px;
      // cursor: pointer;
    }
    .applink {
      a {
        margin: 10px 0 0 10px;
        &:first-child {
          margin-left: 0;
        }
        width: fit-content;
        // height: 2rem;
        @media (max-width: 992px) {
          margin: 0 auto;
          padding-bottom: 9px;
          margin-left: 0;
          display: block;
        }
        svg {
          height: 2.5rem;
          width: auto;
          transition-duration: 0.5s;
          &:hover {
            transform: scale(1.1);
          }
          @media (max-width: 992px) {
            height: 3rem;
          }
        }
      }
      @media (max-width: 992px) {
        // margin: 0 auto;
        margin-bottom: 40px;
        width: fit-content;
      }
    }
  }
}
