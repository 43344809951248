.sideslider {
  list-style: none;
  color: $primary;
  font-family: "Regular";
  position: fixed;
  top: 20%;
  left: -80px;
  z-index: 5;
  box-sizing: border-box;
  transform: translateX(-100%);
  @media (max-width: 992px) {
    display: none;
  }
  .link {
    transition-duration: 0.5s;
    background-color: $white;
    border: $white 2px solid;
    border-radius: 59px;
    margin-bottom: 10px;
    transform: translateX(60%);
    span {
      display: flex;
      padding: 0 20px 0 80px;
      font-size: 18px;
      align-items: center;
      cursor: pointer;
      .link-icon {
        margin-left: 50px;
        svg {
          width: 40px;
          * {
            transition-duration: 0.5s;
          }
          path {
            fill: #848484;
          }
        }
      }
      &:hover {
        .link-icon {
          svg {
            path {
              fill: $primary;
            }
          }
        }
      }
    }
    &:hover {
      transform: translateX(110%);
      border: $primary 2px solid;
    }
    &:has(.active) {
      border: $primary 2px solid;
    }
    .active {
      .link-icon {
        svg {
          path {
            fill: $primary;
          }
        }
      }
    }
  }
}
