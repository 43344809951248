@import './abstracts/color';
@import './abstracts/fonts';

@import './layout/navbar';
@import './layout/footer';

@import './components/welcometo';
@import './components/sideslider';
@import './components/ourclients';
@import './components/services';
@import './components/products';
@import './components/cards/prodactCard';
@import './components/solutions';
@import './components/contactus';
@import './components/questionmodal';

@import './pages/home';
@import './pages/privacyPolicy';

body {
  margin: 0;
  background-color: $gray;
  a {
    text-decoration: none;
    color: $white;
    &:hover {
      color: $primary;
    }
  }
}
html {
  scroll-behavior: smooth;
}
