.privacyPolicy {
  padding: 10vh 0;
  min-height: 100vh;
  * {
    font-family: "Regular";
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'SemiBold' !important;
  }
}
