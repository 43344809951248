.pordactCard {
  position: relative;
  overflow: hidden;

  .productImg {
    object-fit: cover;
    width: 100%;
    height: 30vh;
    background-color: #fff;
  }

  .info {
    background-color: $white;
    padding: 10px;
    .ant-rate {
      font-size: 14px;
      color: $dark;
      margin-bottom: 6px;
      .ant-rate-star-zero {
        svg {
          path:first-child {
            fill: $white !important;
          }
          path:last-child {
            fill: $primary !important;
          }
        }
      }
      .ant-rate-star-full {
        svg {
          path:first-child {
            fill: $primary !important;
          }
          path:last-child {
            fill: $primary !important;
          }
        }
      }
    }
    .name {
      font-family: 'Medium';
    }
    .brand {
      font-size: 12px;
      font-family: 'Regular';
    }
  }
}
