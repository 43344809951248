.line {
  background-color: $primary;
  width: 100%;
  height: 1vh;
}
.footer {
  * {
    font-family: "Roman";
  }
  color: $white;
  background-color: $dark;
  padding: 20px 0;
  .soon-container {
    cursor: default;
    position: relative;
    width: auto;
    filter: blur(1px);
  }
  .coming-soon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    font-family: "Medium";
    color: $white;
    text-align: center;
    background-color: $primary;
    padding: 10px 20px;
    border-radius: 10px;
  }
  .title {
    font-size: 13px;
    padding: 10px 0 15px 0;
    .footerlinks {
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 30vh;
      margin-bottom: 10px;
      @media (max-width: 992px) {
        height: auto;
      }
      .titlelink {
        font-family: "BT-Bold";
        height: 4rem;
        margin-top: 10px;
        @media (max-width: 992px) {
          text-align: center;
        }
      }
      .logo {
        height: 3rem;
        @media (max-width: 992px) {
          margin: 0 auto;
          display: block;
        }
      }
      .footeritems {
        list-style: none;
        padding-left: 10px;
        padding: 0;
        font-size: 13px;
        li {
          padding-bottom: 10px;
          @media (max-width: 992px) {
            text-align: center;
          }
          span {
            cursor: pointer;
          }
        }
      }
    }
  }

  .fs-13 {
    font-size: 13px;
  }
  .app {
    font-family: "BT-Bold";
    margin-bottom: 0.5rem;
    @media (max-width: 992px) {
      text-align: center;
      margin-bottom: 10px;
    }
  }
  .applink {
    a {
      margin: 10px 0 0 10px;
      &:first-child {
        margin-left: 0;
      }
      width: fit-content;
      // height: 2rem;
      @media (max-width: 992px) {
        margin: 0 auto;
        padding-bottom: 10px;
        display: block;
      }
      svg {
        height: 2rem;
        width: auto;
        transition-duration: 0.5s;
        &:hover {
          transform: scale(1.1);
        }
        @media (max-width: 992px) {
          height: 3rem;
        }
      }
    }
    @media (max-width: 992px) {
      margin: 0 auto;
      margin-bottom: 40px;
      width: fit-content;
    }
  }
  .subinput {
    .ant-input-group-compact {
      .ant-input {
        border-radius: 3px 0 0 3px;
        font-size: 11px;
        height: 28px;
        @media (max-width: 992px) {
          width: calc(100% - 80px) !important;
        }
      }
      .ant-btn {
        border-radius: 0 3px 3px 0 !important;
        border: none;
        background-color: $primary;
        font-size: 11px;
        height: 28px;
      }
      @media (max-width: 992px) {
        margin: 0 auto !important;
        display: block !important;
        margin: 30px 0 !important;
      }
    }
  }
  .contact {
    text-align: center;
    .contact-link {
      div {
        display: inline-block;
        padding: 0 10px;
      }
    }
    & > div:not(:last-child) {
      margin-bottom: 10px;
    }
    svg {
      height: 0.9rem;
      width: auto;
    }
    .follow {
      *:not(:last-child) {
        padding-right: 1.4rem !important;
      }
    }
    @media (max-width: 992px) {
      margin: 0 auto;
      display: block;
    }
  }
  a:hover {
    color: inherit;
  }
}
.copyright {
  direction: ltr;
  font-family: "Roman";
  background-color: $primary;
  text-align: center;
  color: $white;
  padding: 8px;
  margin: 0 auto;
}
