.modal {
  font-family: 'Roman';
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-header {
      border-bottom: 0;
      border-radius: 10px 10px 0 0;
    }
    .ant-modal-body {
      background-color: $gray;
      border-radius: 10px;
      padding: 20px;
      margin: 24px;
      label {
        width: 100%;
      }
      .ant-input {
        border-radius: 7px;
        padding: 5px 20px;
        resize: none;
        &:hover {
          border-color: $primary;
        }
        &:focus {
          border-color: $primary;
          box-shadow: rgba(19, 103, 176, 0.2);
        }
      }
    }
    .ant-modal-footer {
      border-top: 0;
      .ant-btn-primary {
        background-color: $primary;
        border-radius: 5px;
        color: $white;
        border: none;
      }
      .ant-btn-default {
        border-radius: 5px;
        &:hover {
          color: $primary;
          border-color: $primary;
        }
      }
    }
    .captcha-img {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 120px;
    }
  }
}
