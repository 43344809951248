.servicesSlider {
  background-color: #fff;
  font-family: "Regular" !important;
  margin-bottom: 10vh;
  .title {
    font-family: "Bold";
    padding-top: 30px;
    font-size: 20px;
    text-align: center;
    color: $primary;
  }
  .ant-carousel {
    height: 100%;
    .slider {
      height: 100%;
      padding-block: 10vh;
      .service {
        color: $dark;
        .text {
          text-align: center;
          margin: 0 auto;
          width: 70%;
          font-family: "Regular" !important;
        }
        .img {
          width: 100px;
          height: 100px;
          margin: 0 auto 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition-duration: 0.5s;
          &:hover {
            transform: scale(1.5);
          }
          svg {
            padding: 3px;
            width: 70%;
            height: 70%;
          }
        }
      }
      .slick-next {
        right: -40px !important;
      }
      .slick-prev {
        left: -40px !important;
      }
      .slick-arrow {
        width: 1rem;
        z-index: 3;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
