.clients {
  height: 100vh;
  font-family: 'Bold';
  text-align: center;
  .title {
    color: $primary;
    font-size: 20px;
    margin-bottom: 10vh;
  }
  .client-container {
    height: 60vh;
    padding: 10px 0;
    overflow-y: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    .loading {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 3rem;
        width: 3rem;
        path {
          fill: $primary;
        }
      }
    }
    .client-logo {
      height: 100%;
      object-fit: contain;
      width: 100%;
      transition-duration: 0.5s;
      &:hover {
        transform: scale(1.5);
      }
    }
  }
}
