.navbar {
  * {
    font-family: "Roman";
  }
  color: $white;
  .line {
    height: 0.5rem;
    background-color: $primary;
  }
  .nav {
    background-color: $dark;
    padding: 10px 20px;
    .logo {
      height: 3rem;
    }
    .navlinks {
      list-style: none;
      margin-top: 0;
      @media (max-width: 992px) {
        display: none;
      }
      .link {
        display: inline-flex;
        padding: 10px 40px 10px 0;
        align-items: baseline;
        cursor: pointer;

        transition-duration: 0.5s;
        &:hover {
          color: $primary;
        }
        .linkicon {
          height: 0.9rem;
          width: auto;
          margin-right: 10px;
        }
        .linktext {
          display: inline-block;
        }
      }
    }
    .drawerbtn {
      background-color: transparent;
      border: 0;
      color: white;
      padding: 0;
      svg {
        height: 1.5rem;
        width: 1.5rem;
        margin-bottom: -6px;
      }
    }
  }
}

.drawer {
  color: $white;
  font-family: "Roman";
  li {
    width: fit-content;
    margin: 0 auto;
  }
  .ant-drawer-header,
  .ant-drawer-body {
    border-bottom: 0;
    background-color: $dark;
    .ant-drawer-close {
      color: $white;
    }
    .ant-drawer-title {
      img {
        object-fit: contain;
      }
    }
  }
  .menu {
    list-style: none;
    padding: 0;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20%;
    .link {
      font-size: 20px;
      padding-bottom: 10px;
      transition-duration: 0.5s;
      &:hover {
        color: $primary;
      }
      .linkicon {
        padding-left: 10px;
        height: 0.9rem;
        margin-right: 10px;
      }
      .linktext {
        display: inline-block;
        cursor: pointer;
      }
    }
  }
  .contact {
    list-style: none;
    padding: 0;
    text-align: center;
    .contactlink {
      font-size: 18px;
      margin-bottom: 20px;
      a {
        color: $white;
      }
      svg {
        padding-left: 10px;
        height: 100%;
        width: auto;
      }
      .follow {
        * {
          display: inline;
          margin-left: 1.2rem;
        }
      }
    }
  }
}
