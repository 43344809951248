.contactus {
  text-align: center;
  padding: 10vh 0;
  margin-bottom: 10vh;
  @media (max-width: 992px) {
    padding-bottom: 0;
    margin-bottom: 1vh;
  }
  .title {
    font-size: 20px;
    color: $primary;
    font-family: 'Bold';
    margin-bottom: 30px;
  }
  .text {
    font-size: 18px;
    color: $dark;
    font-family: 'Bold';
    margin-bottom: 70px;
    @media (max-width: 992px) {
      margin-bottom: 20px;
    }
  }
  .contact {
    svg {
      height: 2rem;
      width: 2rem;
      padding: 5px;
      margin: 10px;
    }
    div {
      font-family: 'Roman';
      color: $dark;
      @media (max-width: 992px) {
        margin-bottom: 10px;
      }
    }
  }
}
