.products {
  margin-bottom: 10vh;
  position: relative;
  @media (max-width: 992px) {
    margin-bottom: 1vh;
  }
  .coming-soon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    font-family: 'Medium';
    color: $primary;
    font-size: 30px;
    @media (max-width: 992px) {
      font-size: 18px;
    }
    text-align: center;
    background-color: white;
    padding: 10px 20px;
    border-radius: 15px;
    box-shadow: 1px 1px 12px rgba($color: #000000, $alpha: 0.1);
  }
  .title {
    color: $primary;
    font-size: 20px;
    font-family: 'Bold';
    text-align: center;
    margin-bottom: 10vh;
  }

  .ant-carousel {
    height: 100%;
    .brandslider {
      user-select: none;
      .slick-track {
        display: inline-flex;
        align-items: center;
        .slick-slide {
          padding: 0 40px;
          img {
            width: 100%;
          }
        }
      }
      .slick-next {
        right: -40px !important;
      }
      .slick-prev {
        left: -40px !important;
      }
      .slick-arrow {
        width: 0.5rem;
        z-index: 3;
        svg{
          width: 100%;
          height: 100%;
        }
      }
    }
    .slider {
      padding-block: 10vh;
      filter: blur(2px);
      .pordactCard {
        margin-inline: 10px !important;
      }
      .slick-arrow {
        z-index: 3;
      }
    }
  }
}
