.solutions {
  background-color: #fff;
  padding: 10vh 0;
  margin-bottom: 10vh;
  @media (max-width: 992px) {
    margin-bottom: 1vh;
  }
  .title {
    font-family: 'Bold';
    font-size: 20px;
    color: $primary;
    margin-bottom: 20px;
  }
  .izone {
    font-size: 20px;
    font-family: 'Bold';
    color: $dark;
  }
  .solution {
    color: $primary;
    font-family: 'Medium';
    font-size: 18px;
  }
  .text {
    color: $dark;
    font-family: 'Regular';
    font-size: 16px;
  }
  .more {
    display: none;
    color: $dark;
    opacity: 30%;
    font-family: 'Bold';
    font-size: 15px;
    float: right;
  }
}
