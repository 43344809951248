@font-face {
  font-family: "Roman";
  src: url("../../assets/fonts/Square721_BT_Roman.ttf");
}
@font-face {
  font-family: "BT-Bold";
  src: url("../../assets/fonts/Square721_BT_Bold.ttf");
}
@font-face {
  font-family: "Regular";
  src: url("../../assets/fonts/Rubik-Regular.ttf");
}
@font-face {
  font-family: "SemiBold";
  src: url("../../assets/fonts/Rubik-SemiBold.ttf");
}
@font-face {
  font-family: "Bold";
  src: url("../../assets/fonts/Rubik-Bold.ttf");
}
@font-face {
  font-family: "Medium";
  src: url("../../assets/fonts/Rubik-Medium.ttf");
}
